<template>
  <v-app id="inspire">
    <v-main class="pt-12 pl-2">
      <v-container>
        <Nav />
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Nav from "../components/admin/sideNav";

export default {
  name: "Admin",

  components: {
    Nav
  }
};
</script>
