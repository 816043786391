<template>
  <v-navigation-drawer permanent left fixed app dark>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          System Administrator
        </v-list-item-title>
        <v-list-item-subtitle>
          Zivai Charivanda
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        @click="goto(item.url)"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard", url: "Admin" },
        { title: "Orders", icon: "mdi-cash", url: "Orders" },
        { title: "Products", icon: "mdi-cart", url: "Products" },
        { title: "Users", icon: "mdi-account", url: "Admin" }
      ],
      right: null
    };
  },
  methods: {
    goto(path) {
      this.$router.push({ name: path });
    }
  }
};
</script>
